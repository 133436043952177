import React from 'react';
import { Text, View, Platform, Pressable } from 'react-native';

import { styles } from './styles';
import CachedImage from '../CachedImage';
import { defaultTheme } from '../../themes/loot8';
import useIsResponsive from '../../hooks/useIsResponsive';

const isWeb = Platform.OS === 'web';

interface ExPassAssetsCardProps {
  item: any;
  onPress: () => void;
}

const ExPassAssetsCard: React.FC<ExPassAssetsCardProps> = ({
  item,
  onPress,
}) => {
  const isResponsive = useIsResponsive();

  return (
    <Pressable
      onPress={onPress}
      style={[
        styles.container,
        { flex: isResponsive ? 0.49 : 1, height: item.subtitle || isWeb ? 181 : 166 },
      ]}>
      <RenderCachedImage
        item={item}
        isResponsive={false}
        resizeMode={'cover'}
      />
      <View style={styles.right}>
        <Text style={styles.title} numberOfLines={1}>
          {item?.name}
        </Text>
        {item.subtitle && (
          <Text style={[styles.subtitle, { marginTop: 5 }]} numberOfLines={1}>
            {item.subtitle}
          </Text>
        )}
      </View>
    </Pressable>
  );
};

export default ExPassAssetsCard;

const RenderCachedImage = ({
  item,
  isResponsive,
  type,
  hovered,
  resizeMode,
}: any) => {
  const uri = item?.animationUrl
    ? item?.animationUrl
    : item?.thumbnailImage !== ''
    ? item?.thumbnailImage
    : item?.image;

  return (
    <CachedImage
      isBackground
      isAnimationUrl={!!item?.animationUrl}
      isResponsive={isResponsive}
      item={item}
      type={type}
      hovered={hovered}
      noImageOnError={true}
      source={{
        uri,
      }}
      isThumbnailImage={item?.thumbnailImage !== '' ? true : false}
      imageStyle={{
        borderTopLeftRadius: defaultTheme.CONTENT_RADIUS,
        borderTopRightRadius: defaultTheme.CONTENT_RADIUS,
      }}
      style={{
        width: '100%',
      }}
      contentFit={Platform.OS == 'android' ? 'cover' : resizeMode}
      imageSize={item?.imageSize}
      thumbnailImageSize={item?.thumbnailImageSize}
      optimizedImageSize={item?.optimizedImageSize}
    />
  );
};
